@charset "UTF-8";

@import "settings";
@import "mixins";


/*****************************************************************************
Responsive Block
*****************************************************************************/
#Wrapper {
  position: relative;
  left: 0;
}
.responsive-block-open #Wrapper {
  left: 0;
  @include transition(all 0.6s ease);
}
#responsive-block {
  visibility: hidden;
  position: fixed;
  left: -400px;
  top: 0;
  z-index: 9999;
  width: 400px;
  height: 100%;
  background: $color-links;
  @include transition(all 0.6s ease);
  .lang {
    text-align:center;
    li {
      display:inline-block;
      a {
        display:inline-block;
        padding:13px 15px 12px;
        color:#eff2f7;
        line-height:20px;
        font-size:16px;
        font-weight:300;
        text-transform:uppercase;
        &:hover {
          color: $color-links;
        }
      }
      &.active a {
        font-weight:700;
      }
    }
  }
  nav li a {
    display:block;
    padding: 15px 30px;
    color: #fff;
    line-height: 20px;
    font-size: 18px;
    font-weight:300;
    @include transition(all 0.2s ease);
  }
  nav > ul > li > a:hover, nav > ul > li:hover > a, nav > ul > li.active > a {
    background-color: #22bb75;
    color: #fff;
  }
  .submenu .level-links {
    visibility:hidden;
    position:absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    background-color: $color-links;
    @include transition(all 0.6s ease);
    li {
      border-bottom: 1px solid #22bb75;
      a {
        color: #fff;
        @include transition(all 0.2s ease);
        &:hover {
          color: #22bb75;
        }
      }
    }
    .back {
      padding: 15px 30px;
      background: #22bb75 url(../img/arrow-left-12x23.png) no-repeat 10px center;
      color: #fff;
      line-height: 20px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .bottom-social {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    background: $color-links;
    a {
      display:block;
      float:left;
      width:100%;
      height:50px;
      color: #fff;
      line-height:50px;
      font-size:17px;
      font-weight:500;
      text-align:center;
      @include transition(all 0.3s ease);
      &:hover {
        color: #fff;
      }
    }
  }
  .submenu.active .level-links {
    visibility:visible;
    left:0;
  }
}
.responsive-block-open #responsive-block {
  visibility: visible;
  left: 0;
  @include transition(all 0.6s ease);
}

/*****************************************************************************
Header
*****************************************************************************/
#Header {
  position: fixed;
  z-index: 1040;
  width: 100%;
  padding: 0 77px;
  background-color: #fff;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
  .header-content {
    display: flex;
    justify-content: space-between;
    .left-col {
      display: flex;
      align-items: center;
      padding: 4px 60px 0 0;
      @include transition(all 0.4s ease);
      a.logo {
        display:block;
        width: 120px;
        height: 137px;
        background: url(../img/logo.png) no-repeat 0 0;
        background-size: 100% auto;
        text-indent: -9999px;
        @include transition(all 0.4s ease);
      }
      h2 {
        margin-left: 28px;
        color: #0b9138;
        font-family: 'Jost', sans-serif;
        font-size: 25px;
        line-height: 26px;
        font-weight: 700;
        @include transition(all 0.4s ease);
        a {
          color: #0b9138;
        }
      }
    }
    .right-col {
      flex: 1;
      .top-bar {
        visibility: visible;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 70px;
        @include transition(all 0.4s ease);
        .phone {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            &.tel {
              color: #080808;
              font-size: 17px;
              line-height: 17px;
              font-weight: 500;
              a {
                color: #080808;
              }
            }
            &.text {
              margin-right: 16px;
              color: #5b5b5b;
              font-size: 13px;
              line-height: 15px;
              font-weight: 500;
            }
          }
        }
        .email {
          margin: 0 63px 0 13px;
          padding-left: 20px;
          background: url(../img/icons/slash.png) no-repeat 0 0;
          line-height: 35px;
          a {
            color: #080808;
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
            @include transition(all 0.3s ease);
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .menu-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 86px;
        border-top: 1px solid #e5e5e5;
        .social-icon {
          display: flex;
          align-items: center;
          background: url(../img/separate.png) no-repeat 0 center;
          margin-left: 32px;
          padding-left: 26px;
          a {
            display: block;
            width: 33px;
            height: 34px;
            margin-left: 11px;
            text-indent: -9999px;
            @include transition(all 0.3s ease);
            &:first-child {
              margin-left: 0;
            }
            &.fb {
              background: url(../img/icons/fb.png) no-repeat 0 0;
            }
            &.yt {
              background: url(../img/icons/yt.png) no-repeat 0 0;
            }
            &.in {
              background: url(../img/icons/in.png) no-repeat 0 0;
            }
          }
        }
        .visually-impaired {
          padding-left: 26px;
          margin-left: 27px;
          background: url(../img/separate.png) no-repeat 0 center;
          a.vi {
            display: block;
            width: 34px;
            height: 34px;
            text-indent: -9999px;
            @include transition(all 0.3s ease);
            background: url(../img/icons/eye.png) no-repeat 0 0;
          }
        }
      }
    }
  }
}

#Header.sticky {
  .header-content {
    .left-col {
      padding-right: 0;
      padding-top: 0;
      display: flex;
      align-items: center;
      a.logo {
        width: 66px;
        height: 75px;
      }
      h2 {
        margin-left: 15px;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .right-col {
      .top-bar {
        visibility: hidden;
        height: 0;
      }
      .menu-bar {
        border-top: 0;
      }
    }
  }
}

#menu {
  & > ul {
    display: flex;
    & > li {
      position: relative;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      & > a {
        display: block;
        padding: 0 25px;
        line-height: 85px;
        color: #222;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-links;
        }
      }
      &:last-child > a {
        padding-right: 0;
      }
      &.active > a {
        color: $color-links;
      }
      &:hover > a {
        color: $color-links;
      }
      .level-links {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 85px;
        right: -60px;
        z-index: 9;
        width: 300px;
        padding: 28px 35px;
        @include box-shadow(0 0 15px rgba(0, 0, 0, 0.3));
        background: #10923b;
        background: -webkit-linear-gradient(to right, #10923b , #179a42);
        background: -o-linear-gradient(to right, #10923b , #179a42);
        background: -moz-linear-gradient(to right, #10923b , #179a42);
        background: linear-gradient(to right, #10923b , #179a42);
        @include transition(all 0.3s ease);
        .links li {
          text-transform: none;
          a {
            display: flex;
            padding: 8px 0;
            color: #fff;
            font-size: 15px;
            line-height: 17px;
            font-weight: 500;
            &:hover {
              text-decoration: underline;
            }
            &:before {
              content: "";
              float: left;
              margin: 7px 15px 0 0;
              width: 4px;
              height: 4px;
              background-color: #fff;
              @include border-radius(4px);
              text-shadow: 1px 1px #b6040b;
            }
            &:hover:before {
              background-color: #fff;
            }
          }
        }
      }
      &:hover {
        .level-links {
          visibility: visible;
          opacity: 1;
        }
      }
      &.submenu {
        &:after {
          visibility: hidden;
          opacity: 0;
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: $color-links;
        }
        &:hover {
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#nav-icon span {
  background-color: #000;
}

/*****************************************************************************
Content
*****************************************************************************/
#Content {
  padding-top: 156px;
}

.text-line {
  display: flex;
  align-items: center;
  h3 {
    margin-right: 21px;
    color: #222;
    font-family: 'Jost', sans-serif;
    font-size: 34px;
    line-height: 34px;
    font-weight: 500;
    a {
      color: #222;
    }
  }
  .line {
    margin-top: 5px;
    flex: 1;
    height: 1px;
    background-color: #d7d6d6;
  }
  .links {
    margin-left: 26px;
    font-family: 'NexaBold';
    font-size: 17px;
    text-transform: uppercase;
    a {
      &.green {
        color: #0d6d68;
      }
      &.gold {
        color: $color-links;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      margin: 0 23px 0 21px;
      color: #d7d6d6;
    }
  }
}

h3.line {
  color: #292a2e;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  &:after {
    content: '';
    display: block;
    margin: 13px auto 0;
    width: 226px;
    height: 2px;
    background: #e2001a;
  }
  a {
    color: #292a2e;
    &:hover {
      color: $color-links;
    }
  }
}

.headline-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 31px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e5e5e5;
  h3 {
    color: #222;
    font-family: 'Jost', sans-serif;
    font-size: 34px;
    line-height: 34px;
    font-weight: 400;
    a {
      color: #222;
    }
  }
  a.more {
    position: relative;
    display: block;
    padding-right: 30px;
    background: url(../img/icons/arrow-right.png) no-repeat right center;
    color: #99999b;
    font-size: 14px;
    font-weight: 600;
    @include transition(all 0.3s ease);
    &:after {
      position: absolute;
      bottom: -15px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background-color: $color-links;
    }
    &:hover {
      color: $color-links;
    }
  }
}

#slider-main-section {
  background-color: #f6f6f6;
  .inside {
    display: flex;
    justify-content: space-between;
    padding-bottom: 49px;
  }
  .slider-main-content {
    position: relative;
    width: 1040px;
    .bx-viewport {
        overflow: visible !important;
    }
    .slider-main {
      .slide {
        .photo {
          height: 570px;
          background: #000;
          img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            opacity: 0.7;
          }
        }
        .desc {
          position: absolute;
          bottom: 46px;
          left: -92px;
          z-index: 9999;
          width: 600px;
          height: 179px;
          display: flex;
          align-items: center;
          background: url(../img/slide-desc.png) no-repeat 0 0;
          @include box-shadow(0 4px 15px 0px rgba(24, 24, 24, 0.3));
          h2 {
            margin-left: 90px;
            color: #fff;
            font-family: 'Jost', sans-serif;
            font-size: 40px;
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: 1px 1px rgba(24,24,24,0.5);;
          }
        }
      }
    }
    .bx-controls {
      position: absolute;
      bottom: 112px;
      right: 70px;
      z-index: 88;
      width: 44px;
      margin: 0 auto;
      .bx-pager {
        .bx-pager-item {
          .bx-pager-link {
            display: block;
            margin-bottom: 9px;
            width: 22px;
            height: 3px;
            background-color: rgba(255,255,255,0.5);
            text-indent: -9999px;
            @include border-radius(1px);
            @include transition(all 0.3s ease);
            &.active {
              width: 44px;
              background-color: rgba(255,255,255,1);
            }
          }
        }
      }
    }
  }

  .right-col {
    width: 323px;
    .rsb {
      height: 266px;
      margin-bottom: 35px;
      padding-top: 44px;
      background: #fff;
      text-align: center;
      @include border-radius(0 0 5px 5px);
      a {
        display: block;
        img {
          display: block;
          margin: 0 auto 15px;
        }
        .more {
          position: relative;
          display: inline-block;
          color: #212b34;
          font-size: 17px;
          line-height: 21x;
          font-weight: 500;
          @include transition(all 0.3s ease);
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #094722;
            @include transition(all 0.3s ease);
          }
          &:hover {
            color: $color-links;
            &:after {
              background: $color-links;
            }
          }
        }
      }
    }
    .mini-slider-box {
      position: relative;
      height: 269px;
      @include border-radius(5px);
      .slide {
        .photo {
          img {
            display: block;
            margin: 0 auto;
          }
        }
        .desc {
          position: absolute;
          top: 83px;
          left: 105px;
          h4 {
            margin-bottom: 17px;
            color: #fff;
            font-size: 21px;
            font-weight: 900;
            text-transform: uppercase;
          }
          .more {
            position: relative;
            display: inline-block;
            color: #22af49;
            font-size: 17px;
            font-weight: 500;
            @include transition(all 0.3s ease);
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #269145;
              @include transition(all 0.3s ease);
            }
            &:hover {
              color: #fff;
              &:after {
                background: #fff;
              }
            }
          }
        }
      }
      .bx-controls {
        position: absolute;
        bottom: 25px;
        right: 25px;
        z-index: 88;
        .bx-pager {
          display: flex;
          .bx-pager-item {
            margin-left: 10px;
            .bx-pager-link {
              position: relative;
              display: block;
              width: 6px;
              height: 6px;
              background-color: rgba(246,246,246,0.2);
              text-indent: -9999px;
              @include border-radius(50%);
              @include transition(all 0.3s ease);
              &.active {
                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  top: -4px;
                  left: -4px;
                  width: 14px;
                  height: 14px;
                  border: 1px solid rgba(246,246,246,0.2);
                  @include border-radius(50%);
                }
              }
            }
          }
        }
      }
    }
  }
}

#news {
  margin: 65px 0 68px;
  .items {
    display: flex;
    justify-content: space-between;
    .item {
      width: 414px;
      a {
        display: block;
        position: relative;
        .top {
          position: absolute;
          top: 14px;
          right: 0;
          z-index: 999;
          display: inline-block;
          padding: 0 13px;
          color: #fff;
          font-size: 11px;
          line-height: 26px;
          font-weight: 600;
          &.green {
            background-color: #22a94f;
          }
        }
        .photo {
          height: 254px;
          overflow: hidden;
          img {
            margin: 0 auto;
            display: block;
            width: 100%;
            @include transition(all 0.4s ease);
          }
        }
        .desc {
          margin-top: 35px;
          p {
            margin-bottom: 25px;
            color: #333;
            font-size: 19px;
            line-height: 29px;
            font-weight: 500;
            @include transition(all 0.3s ease);
          }
          time {
            display: block;
            color: #acadb0;
            font-size: 13px;
            font-weight: 600;
          }
        }
        &:hover {
          text-decoration: none;
          .photo {
            img {
              @include transform(scale(1.1));
              @include transition(all 0.4s ease);
            }
          }
          .desc p {
            text-decoration: underline;
          }
        }
      }

    }
  }
  &.news-subpage .items {
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
      margin-bottom: 65px;
      margin-right: 79px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      a .desc p {
        margin-bottom: 10px;
      }
    }
  }
}

#video {
  margin-bottom: 80px;
  .inside {
    display: flex;
    justify-content: space-between;
    .video-list {
      width: 1040px;
      h3 {
        margin-bottom: 53px;
        padding-bottom: 13px;
        border-bottom: 1px solid #d7d6d6;
        color: #222;
        font-family: 'Jost', sans-serif;
        font-size: 34px;
        line-height: 34px;
        font-weight: 500;
        a {
          color: #222;
        }
      }
      .items {
        display: flex;
        justify-content: space-between;
        .item {
          position: relative;
          width: 328px;
          height: 252px;
          overflow: hidden;
          a {
            display: block;
            height: 100%;
            background: #000;
            @include border-radius(5px);
            img {
              display: block;
              margin: 0 auto;
              max-width: 100%;
              @include border-radius(5px);
              @include transition(all 0.3s ease);
            }
            .play {
              position: absolute;
              top: calc(50% - 24px);
              left: calc(50% - 21px);
              width: 43px;
              height: 48px;
              background: url(../img/icons/play.png) no-repeat 0 0;
            }
            &:hover img {
              opacity: 0.7;
            }
          }
        }
      }
    }
    .green-box {
      width: 324px;
      height: 351px;
      padding: 49px 20px 0;
      text-align: center;
      background: url(../img/green-box.jpg) no-repeat 0 0;
      a {
        display: block;
        height: 100%;
      }
      h3 {
        margin-bottom: 9px;
        color: #fff;
        font-family: 'Jost', sans-serif;
        font-size: 36px;
        font-weight: 600;
      }
      h4 {
        margin-bottom: 26px;
        color: #fff;
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
      }
      .line {
        margin: 0 auto 21px;
        width: 207px;
        height: 1px;
        background: #108035;
      }
      p {
        margin-bottom: 34px;
        color: #fff;
        font-family: 'Jost', sans-serif;
        font-size: 19px;
        line-height: 24px;
        font-weight: 400;
      }
      .more {
        display: inline-block;
        position: relative;
        color: #094722;
        font-size: 17px;
        line-height: 21px;
        font-weight: 500;
        @include transition(all 0.3s ease);
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #094722;
          @include transition(all 0.3s ease);
        }
        &:hover {
          color: #fff;
          &:after {
            background: #fff;
          }
        }
      }
    }
  }
}

#partners {
  padding: 0 0 1px;
  .inside {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
  .slider-partners-content {
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 103px;
      img {
        display: block;
        max-width: 100;
      }
    }
  }
  .side-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 80px;
    padding: 40px 0;
    border-top: 1px solid #d7d6d6;
    border-bottom: 1px solid #d7d6d6;
    h5 {
      margin: 0 22px 5px 0;
      color: $color-links;
      font-size: 17px;
      font-weight: 500;
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      a.tags-a {
        color: #666;
        font-size: 13px;
        font-weight: 300;
        padding: 6px 10px;
        background: #f6f6f6;
        margin: 0 5px 5px 0;
        display: block;
        @include border-radius(5px);
        @include transition(all 0.3s ease);
        &:hover {
          background: #888b91;
          color: #fff;
        }
      }
    }
  }
  .subsidy {
    margin-left: 314px;
    padding: 80px 0 80px 227px;
    background: url(../img/niw.png) no-repeat 0 center;
    h4 {
      color: #666;
      font-size: 19px;
      line-height: 30px;
      font-weight: 400;
    }
  }
}

#bottom-info {
  padding: 77px 0 53px;
  background-color: #f6f6f6;
  .items {
    display: flex;
    justify-content: space-between;
    padding: 0 33px 0 40px;
    .item {
      &:first-child {
        width: 29%;
      }
      &:nth-child(2) {
        width: 32%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:last-child {
        width: 14%;
        h5, ul {
          text-align: right;
        }
      }
      h5 {
        margin-bottom: 20px;
        color: #333;
        font-size: 17px;
        font-weight: 600;
      }
      p {
        margin-bottom: 30px;
        color: #333;
        font-size: 17px;
        line-height: 21px;
        font-weight: 300;
        a {
          color: $color-links;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      ul {
        li {
          padding-bottom: 8px;
          color: #333;
          font-size: 17px;
          font-weight: 300;
          a {
            color: #333;
            &:hover {
              color: $color-links;
            }
          }
        }
        &.disc {
          li {
            position: relative;
            padding-left: 19px;
            &:before {
              content: '';
              position: absolute;
              top: 11px;
              left: 0;
              display: block;
              width: 5px;
              height: 5px;
              background-color: #333;
              @include border-radius(50%);
            }
          }
        }
      }
    }
  }
}

/*****************************************************************************
Footer
*****************************************************************************/
#Footer {
  padding: 49px 0 18px;
  background-color: #222;
  .social-links {
    color: #a9aaab;
    .container {
      position: relative;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #222;
      a {
        display: block;
        margin: 0 22px 0 18px;
        width: 105px;
        height: 120px;
        background: url(../img/logo-black.jpg) no-repeat 0 0;
        background-size: 100% auto;
        text-indent: -9999px;
      }
    }
    .inside {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 56px;
      .social-icons {
        display: flex;
        align-items: center;
        p {
          margin: 0 13px 0 0;
          color: #a9aaab;
          font-size: 15px;
          font-weight: 400;
        }
        ul {
          display: flex;
          padding: 0;
          line-height: 25px;
          li {
            padding: 0 10px;
            a {
              font-size: 15px;
              color: #74760a;
              font-weight: 700;
              @include transition(all 0.3s ease);
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .links{
        a {
          font-size: 15px;
          font-weight: 400;
          color: #a9aaab;
          @include transition(all 0.3s ease);
          &:hover {
            text-decoration: underline;
          }
        }
        .space {
          display: inline-block;
          padding: 0 10px;
          color: #a9aaab;
        }
      }
    }
  }
  .menu {
    .inside {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 115px;
      border-top: 1px solid #696969;
      border-bottom: 1px solid #696969;
      .menu-list {
        display: flex;
        li {
          padding: 0 34px;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
          a {
            color: #d4d6d9;
            @include transition(all 0.3s ease);
            &:hover {
              color: $color-links;
            }
          }
        }
      }
    }
  }
  .signature {
    p {
      margin: 0;
      color: #a9aaab;
      font-size: 15px;
      font-weight: 400;
      br {
        display: none;
      }
    }
    .inside {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: #fff;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
      .text-right {
        a {
          font-weight: 500;
        }
      }
    }
  }
}

/*****************************************************************************
Subpages
*****************************************************************************/

.subpage-simple {
  margin: 50px 0 90px;
  .section-photo {
    display: flex;
    .left-col {
      position: relative;
      width: 699px;
      height: 433px;
      margin-bottom: 113px;
      .photo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 577px;
        height: 433px;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .border-photo {
        position: absolute;
        top: 28px;
        left: 55px;
        width: 577px;
        height: 455px;
        border: 14px solid $color-links;
      }
    }
    .right-col {
      flex: 1;
      padding-top: 32px;
    }
  }
}

#contact-page-map {
  position: relative;
  height: 568px;
  background: #eaeaea;
  .city {
    position: absolute;
    right: 62%;
    top: 0;
    width: 736px;
    height: 100%;
    background: url(../img/city.jpg) no-repeat;
  }
  .address-box {
    position: absolute;
    right: 50%;
    top: 84px;
    z-index: 990;
    margin-right: -302px;
    padding: 83px 65px 0;
    width: 605px;
    height: 401px;
    background-color: #fff;
    @include box-shadow(0px 0px 13px 0px rgba(2, 2, 2, 0.4));
    h4 {
      margin-bottom: 8px;
      color: #232427;
      font-size: 24px;
      line-height: 26px;
      font-weight: 600;
    }
    h5 {
      margin-bottom: 25px;
      color: #727272;
      font-size: 17px;
      font-weight: 300;
    }
    p {
      margin-bottom: 25px;
      color: #232427;
      font-size: 19px;
      line-height: 23px;
      font-weight: 400;
      a {
        position: relative;
        color: $color-links;
        @include transition(all 0.3s ease);
        &:after {
          content: '';
          position: absolute;
          bottom: 2px;
          right: 0;
          display: block;
          width: 100%;
          height: 2px;
          background-color: $color-links;
          @include transition(all 0.3s ease);
        }
        &:hover {
          color: #000;
          &:after {
            background-color: #000;
          }
        }
      }
    }
  }
  #map {
    position: absolute;
    right: 0;
    top: 0;
    width: 62%;
    height: 568px;
  }
}

.subpage-contact {
  margin: 92px 0;
  .inside {
    display: flex;
    .left-col {
      width: 566px;
      padding-right: 50px;
      border-right: 1px solid #d0d1d3;
      .resp {
        display: none;
      }
      h3 {
        margin-bottom: 5px;
        color: #232427;
        font-size: 24px;
        font-weight: 500;
      }
      h4 {
        margin-bottom: 50px;
        color: #727272;
        font-size: 17px;
        font-weight: 300;
      }
      p {
        color: #232427;
        font-size: 19px;
        font-weight: 400;
        a {
          color: #7dae11;
          text-decoration: underline;
        }
      }
    }
    .right-col {
      width: 720px;
      padding-left: 66px;
      h3 {
        margin-bottom: 46px;
        color: #222;
        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
      }
    }
  }
}

.files-sb {
  margin: 50px 0 60px;
  h3 {
    margin-bottom: 25px;
    padding-bottom: 14px;
    border-bottom: 1px solid #dbdbdb;
    color: #222;
    font-size: 27px;
    font-weight: 400;
  }
  .items {
    .item {
      margin-bottom: 17px;
      &.pdf {
        background: url(../img/pdf.png) no-repeat 0 center;
      }
      &.doc {
        background: url(../img/doc.png) no-repeat 0 center;
      }
      a {
        display: inline-block;
        padding: 12px 0 12px 76px;
        color: #222;
        font-size: 19px;
        line-height: 30px;
        text-decoration: underline;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-links;
        }
      }
    }
  }
}

.subpage-blog-details {
  margin: 70px 0 90px;
  .inside {
    display: flex;
    justify-content: space-between;
    .blog-details {
      width: 974px;
      .blog-details__header {
        margin-bottom: 50px;
        h3 {
          position: relative;
          margin-bottom: 5px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #333;
          font-size: 36px;
          line-height: 36px;
          font-weight: 500;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 189px;
            height: 3px;
            background-color: $color-links;
          }
        }
        .date {
          color: #969ca1;
          font-size: 14px;
          font-weight: 300;
        }
      }
      img {
        display: block;
        max-width: 100%;
        margin: 40px 0;
      }
    }
    .blog-sidebar {
      width: 374px;
      margin-top: 15px;
      aside {
        margin-bottom: 30px;
        h3 {
          margin-bottom: 43px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #222;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      .widget-latest-news {
        .items {
          .item a {
            display: flex;
            justify-content: space-between;
            margin-bottom: 19px;
            padding-bottom: 16px;
            border-bottom: 1px solid #f3f3f3;
            .photo {
              width: 85px;
              img {
                display: block;
                max-width: 100%;
                @include transition(all 0.3s ease);
              }
            }
            .desc {
              width: 272px;
              p {
                &.date {
                  margin-bottom: 6px;
                  color: #ababad;
                  font-size: 13px;
                  font-weight: 500;
                  text-transform: uppercase;
                }
                &.title {
                  margin: 0;
                  color: #414449;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 17px;
                }
              }
            }
            &:hover {
              .photo {
                img {
                  opacity: 0.9;
                }
              }
              .desc {
                p.title {
                  text-decoration: underline;
                }
              }
            }
          }
          .item:last-child a {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

.subpage-gallery {
  margin-top: 60px;
  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 20px;
      margin-right: 20px;
      &:nth-child(4n) {
        margin-right:0;
      }
      a {
        position: relative;
        display: block;
        width: 335px;
        height: 256px;
        overflow: hidden;
        @include transition(all 0.7s ease);
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          @include transition(all 0.7s ease);
        }
        .bg {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(../img/photo-bg.png) no-repeat center center;
          /*background-color: #07913c;
          mix-blend-mode: multiply;*/
          @include transition(all 0.3s ease);
        }
        &:hover {
          img {
            //@include transform(scale(1.1));
            @include transition(all 0.7s ease);
          }
          .bg {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

.btn-link-col:focus, .btn-link-col.focus{
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25) !important;
}

.list-of-members {
  padding: 50px 0;
  .headline-links h3 {
    font-weight: 600;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .item {
      margin-bottom: 30px;
      margin-right: 13px;
      width: 269px;
      padding: 37px 33px 30px;
      background-color: #f2f2f2;
      @include border-radius(5px);
      &:nth-child(5n) {
        margin-right: 0;
      }
      .photo {
        width: 203px;
        height: 203px;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      .desc {
        margin-top: 25px;
        h6 {
          color: #141414;
          font-family: 'Jost', sans-serif;
          font-weight: 500;
          font-size: 21px;
          line-height: 21px;
          text-align: center;
        }
        p {
          margin: 0;
          color: #8c8b8b;
          font-family: 'Jost', sans-serif;
          font-weight: 300;
          font-size: 15px;
          line-height: 15px;
          text-align: center;
        }
      }
    }
  }
}

.exam-applications {
  padding: 50px 0;
  .headline-links {
    margin-bottom: 50px;
    h3 {
      font-weight: 600;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    .item {
      margin-bottom: 40px;
      margin-left: 26px;
      width: 639px;
      .box-border {
        position: relative;
        border: 2px solid #a1a7b3;
        @include border-radius(5px);
        a {
          display: block;
          padding: 50px 40px 50px 69px;
          color: $color-links;
          font-family: 'Jost', sans-serif;
          font-weight: 500;
          font-size: 21px;
          line-height: 21px;
          @include transition(all 0.3s ease);
          &:hover {
            color: #222;
          }
        }
        span.bg {
          position: absolute;
          top: 20px;
          left: -27px;
          display: block;
          width: 70px;
          height: 84px;
          background: url(../img/applications.png) no-repeat 0 0;
        }
      }
      a.file {
        display: block;
        margin-top: 10px;
        color: #767475;
        font-size: 19px;
        font-weight: 600;
        text-align: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.catalog {
  padding: 0 0 100px;
  .top {
    margin: 83px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .filtration {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      border-bottom: 1px solid #c9cacf;
      a {
        display: block;
        margin: 0 11px 16px 0;
        padding: 7px 17px;
        background: #f2f2f2;
        color: #6f7175;
        font-family: 'Jost', sans-serif;
        font-weight: 400;
        font-size: 17px;
        @include border-radius(3px);
        @include transition(all 0.3s ease);
        &.active, &:hover {
          background: #888b91;
          color: #fff;
        }
        &.all {
          padding-left: 34px;
          padding-right: 34px;
        }
      }
    }
    .search {
      width: 380px;
      margin-left: 9px;
      border-bottom: 1px solid #c9cacf;
      input.no-border {
        width: 100%;
        padding: 19px 0 12px 41px;
        background: url(../img/icons/loupe.png) no-repeat 6px 22px;
        border: none;
        color: #a9a8a8;
        line-height: 15px;
        font-size: 15px;
        font-weight: 400;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .table {
    thead th {
      width: 25%;
      padding: 0.5rem 1.5rem;
      color: #088a3b;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 1px solid #e1e2e3;
      border-top: 0;
    }
    td {
      padding: 1.5rem;
      color: #424448;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      vertical-align: middle;
      border-top: 1px solid #e1e2e3;
      a {
        color: #424448;
        &:hover {
          color: $color-links;
        }
      }
    }
    tbody tr:hover {
      background-color: #f8f8f9;
    }
  }
  .result-search {
    text-align: center;
  }
}

.subpage-video {
  margin: 60px 0 80px;
  .inside {
    display: flex;
    justify-content: space-between;
    .video-list {
      .items {
        display: flex;
        flex-wrap: wrap;
        .item {
          position: relative;
          width: 328px;
          height: 252px;
          overflow: hidden;
          margin-bottom: 30px;
          margin-right: 29px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          a {
            display: block;
            height: 100%;
            background: #000;
            @include border-radius(5px);
            img {
              display: block;
              margin: 0 auto;
              max-width: 100%;
              @include border-radius(5px);
              @include transition(all 0.3s ease);
            }
            .play {
              position: absolute;
              top: calc(50% - 24px);
              left: calc(50% - 21px);
              width: 43px;
              height: 48px;
              background: url(../img/icons/play.png) no-repeat 0 0;
            }
            &:hover img {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

/*** COLLAPSE bootstrap***/
.accordion > .card .card-header {
    margin-bottom: 0;
}
.card {
  margin-bottom: 20px;
  border: 0;
}
.card-header {
  border: 2px solid #ededed;
  background: none;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  border-radius: 7px !important;
  .btn {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: $color-links;
    padding-right: 25px;
    background: url(../img/icons/arrow_down_grey.png) no-repeat right center;
  }
}
.card-body {
  margin-top: 20px;
  padding: 30px 35px;
  background-color: #fafbfc;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  border-radius: 7px !important;
  article {
    p, ol li, ul li {
      font-size: 17px;
    }
  }
}

.fontsize-switcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  div {
    cursor: pointer;
    font-weight: 600;
    margin-left: 5px;
    color: #c9cacf;
    font-size: 15px;
    line-height: 15px;
    &.fonts-bigger {
      font-size: 18px;
    }
    &.fonts-biggest {
      font-size: 22px;
    }
  }
}

/*****************************************************************************
Visually impaired style
*****************************************************************************/
body.visually-impaired-style {
  background: #000;
  #Header,
  #menu > ul > li .level-links,
  #slider-main-section .slider-main-content .slider-main .slide .desc,
  #slider-main-section .right-col .rsb,
  #responsive-block,
  #bottom-info,
  #responsive-block .submenu .level-links,
  #slider-main-section,
  .subpage-name,
  .card-body,
  .card-header,
  .card {
    background: #000;
  }

  #menu > ul > li .level-links .links li a:before,
  #video .inside .green-box,
  #partners .side-tags .items a.tags-a,
  #nav-icon span,
  .btn-pagination {
    background: #ff0;
  }

  #Header .header-content .left-col h2,
  #menu > ul > li:hover > a,
  #menu > ul > li.active > a,
  #Header .header-content .right-col .top-bar .phone p.text,
  #menu > ul > li .level-links .links li a,
  #slider-main-section .slider-main-content .slider-main .slide .desc h2,
  .headline-links a.more,
  #news .items .item a .desc time,
  #partners .side-tags h5,
  #partners .subsidy h4,
  #responsive-block nav li a,
  #menu > ul > li > a,
  #Header .header-content .right-col .top-bar .phone p.tel a,
  #Header .header-content .right-col .top-bar .email a,
  #slider-main-section .right-col .rsb a .more,
  #slider-main-section .right-col .mini-slider-box .slide .desc h4,
  #slider-main-section .right-col .mini-slider-box .slide .desc .more,
  .headline-links h3 a,
  #news .items .item a .desc p,
  #video .inside .video-list h3 a,
  .text-line h3,
  #Footer .social-links .inside .links a,
  #Footer .menu .inside .menu-list li a,
  #Footer .signature p,
  #Footer .signature .inside a,
  #bottom-info .items .item h5,
  #bottom-info .items .item ul li,
  #bottom-info .items .item ul li a,
  #bottom-info .items .item p,
  #bottom-info .items .item p a,
  #responsive-block .submenu .level-links li a,
  #responsive-block .submenu .level-links .back,
  article p, article h2, article h3, article h4, article h5, article h6,
  article ul li, article ol li, article table, article p a,
  .subpage-name .inside h2,
  .subpage-name .inside .breadcrumb-wg li,
  .subpage-name .inside .breadcrumb-wg li a,
  ul.site-map li a,
  .subpage-blog-details .inside .blog-details .blog-details__header h3,
  .subpage-blog-details .inside .blog-sidebar aside h3,
  .subpage-blog-details .inside .blog-sidebar .widget-latest-news .items .item a .desc p.date,
  .subpage-blog-details .inside .blog-sidebar .widget-latest-news .items .item a .desc p.title,
  .files-sb h3,
  .files-sb .items .item a,
  .card-header .btn {
    color: #ff0;
  }
  #video .inside .green-box h3,
  #video .inside .green-box h4,
  #video .inside .green-box p,
  #video .inside .green-box .more,
  #partners .side-tags .items a.tags-a,
  .btn-pagination {
    color: #000;
  }

  #Header .header-content .right-col .menu-bar {
    border-color: #000;
  }
  #partners .subsidy {
    background: url(../img/niw2.png) no-repeat 0 center;
  }
  #responsive-block .submenu .level-links .back {
    background: #000 url(../img/arrow-left-12x23.png) no-repeat 10px center;
  }
}
